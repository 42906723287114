<template>
  <v-list-item
    :href="item.href"
    :rel="item.href ? 'nofollow' : undefined"
    :target="item.href ? '_blank' : undefined"
    :to="item.to"
    link
    :class="{ primary: active }"
    class="py-1"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list-item-icon
      v-if="!item.icon"
      class="
        text-caption text-uppercase
        justify-center
        ml-1
        my-2
        align-self-center
      "
    >
      {{ title }}
    </v-list-item-icon>

    <v-list-item-avatar v-if="item.avatar">
      <v-img :src="item.avatar" />
    </v-list-item-avatar>

    <v-list-item-icon v-if="item.icon" class="my-2 align-self-center">
      <v-icon v-text="item.icon" />
    </v-list-item-icon>

    <v-list-item-content v-if="item.title">
      <v-list-item-title v-text="item.title" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { trimFirstSlash, getPageNameFromUrl } from "../../utils/RoutesUtils";
export default {
  name: "DefaultListItem",

  data: () => ({
    active: false,
  }),

  created() {
    this.setActiveMenuPage();
  },

  updated() {
    this.setActiveMenuPage();
  },

  watch: {
    $route() {
      this.setActiveMenuPage();
    },
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    title() {
      const matches = this.item.title.match(/\b(\w)/g);
      return matches.join("");
    },
  },

  methods: {
    setActiveMenuPage() {
      const page_name_from_current_route = getPageNameFromUrl(this.$route.path);
      const object_category_from_item_link = trimFirstSlash(this.item.to);

      this.active =
        object_category_from_item_link == page_name_from_current_route ||
        object_category_from_item_link == page_name_from_current_route + "s";
    },
  },
};
</script>
